import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Assuming you have a CSS file for styles
import { useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navLinksRef = useRef(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (navLinksRef.current) {
      console.log(navLinksRef.current.offsetHeight);
    } else {
      console.error("The element .nav-links was not found.");
    }
  }, []);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div class="nav">
      <input
        type="checkbox"
        id="nav-check"
        checked={isNavOpen}
        onChange={handleNavToggle}
      />
      <div className="nav-btn">
        <label htmlFor="nav-check">
          {isNavOpen ? (
            <FaTimes className="material-icons icon" />
          ) : (
            <FaBars className="material-icons icon" />
          )}
        </label>
      </div>

      <div class="nav-links" ref={navLinksRef}>
        <a href="#home">หน้าแรก</a>
        <a href="#news">ข่าวสาร</a>
        <a href="#register">สมัครสมาชิก</a>
        <a href="#login">เข้าเล่น</a>
        <a href="#contact">ติดต่อเรา</a>
      </div>
    </div>
  );
};

export default Navbar;
